import React, { useEffect, useState } from "react"
import { Container, Table, Row, Col, CardBody, Card, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"

//Import Components
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {get} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const UserStats = props => {

    const [user_states, setStatistics] = useState([]);

    const stats = {}

    const getStatistics = () => {
        get(url.USER_STATISTICS)
            .then(res => {

                stats.total_queries = res.total_queries;
                stats.total_users   = res.total_users;
                stats.total_stores  = res.total_stores;

                setStatistics(stats);

            })
            .catch(error => {
                //setErrMessage(error)
            });
    }


    useEffect(() => {
        getStatistics();
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Koloden" breadcrumbItem="Users Information / View Stats" />
                    <Table>
                        <tbody>
                            <tr>
                                <td>Number of Current Users:</td>
                                <td>{user_states.total_users}</td>
                            </tr>
                            <tr>
                                <td>Number of Total Listings:</td>
                                <td>{user_states.total_stores}</td>
                            </tr>
                            <tr>
                                <td>Number of Total Image Queries:</td>
                                <td>{user_states.total_queries}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default UserStats
