import React, { useEffect, useState } from "react"
import { Container, Row, Col, CardBody, Card, CardTitle,
  Alert,
  Dropdown,
  Button,
  Table,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown } from "reactstrap"
import { Link } from "react-router-dom"


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import $ from 'jquery'

import ReCAPTCHA from "react-google-recaptcha";
import {AvForm, AvField } from "availity-reactstrap-validation"
import '../../kolodenTheme/css/bootstrap.min.css'
import '../../kolodenTheme/css/fonts.css'
import '../../kolodenTheme/css/all.min.css'
import '../../kolodenTheme/css/style.css'
import './tester.css'
import MetaTags from 'react-meta-tags';

const Tester = props =>  {

    const showPageData = false;
    const [successMessage, setSuccessMessage] = useState('');
    const [errMessage, setErrMessage] = useState('');
    const [result_data, setResultsData] = useState('');
    const recaptchaRef = React.useRef();
    const [showLoading, setShowLoading] = useState(false);

    const [txtCategory, setTextCategory] = useState('');
    const [txtWordMark, setTextWordMark] = useState('');

    const handleAPIRequest= (event, values) => {
        setSuccessMessage('')
        setErrMessage('')
        $('#msg-error').html('Submit the form to see results here.');

        recaptchaRef.current.execute().then(rsp => {
            const api_data = { token: rsp, category: values['category'], word_mark: values['word_mark'] };
        
            // post("https://koloden.com/apiImpl/trademark/", api_data)
            // .then(response => {
            //     var sHTML = '';
            //     response.map(d => ( 
            //         sHTML = sHTML + '<tr><td>' + d.trademark + '</td><td><a target="_blank" href="http://tsdr.uspto.gov/#caseNumber=' + d.registration + '"&caseSearchType=US_APPLICATION&caseType=SERIAL_NO&searchType=statusSearch">' + d.registration + '</a></td><td><img style="width:30%;" src="https://tsdr.uspto.gov/img/' + d.serial + '/large" /></td><td>' + d.goods_services + '</td></tr>'
            //     ))
            //     if(sHTML == '') { $('#msg-error').html('Your query did not fetch any results.'); }
            //     setResultsData(sHTML);
            // })
            // .catch(error => {
            //     setErrMessage(error)
            //     console.log(error)
            // });
            setShowLoading(true);
            setResultsData('');
            post(url.API_TRADEMARK, api_data)
            .then(response => {
                var sHTML = '';
                if(response?.results?.length) {
                    response.results?.map(d => ( 
                        sHTML = sHTML + '<tr><td>' + d.trademark + '</td><td><a target="_blank" href="http://tsdr.uspto.gov/#caseNumber=' + d.registration + '"&caseSearchType=US_APPLICATION&caseType=SERIAL_NO&searchType=statusSearch">' + d.registration + '</a></td><td><img style="width:30%;" src="https://tsdr.uspto.gov/img/' + d.serial_number + '/large" /></td><td>' + d.goods_services + '</td></tr>'
                    ))
                }
                if(sHTML == '') { $('#msg-error').html('Your query did not fetch any results.'); }
                setResultsData(sHTML);
                setShowLoading(false);

                setTextWordMark(response?.spell?.word_mark);
                setTextCategory(response?.spell?.category);
            })
            .catch(error => {
                setErrMessage(error)
                console.log(error)
                setShowLoading(false);
            });
        })
    }

    $(function() {
        var pageID = 4;
        function loadPageData(){
            get(url.CMS_CONTENT + "?page=" + pageID) 
            .then(res => {
                if(res) {
                    res.forEach(element => {
                        var page = pageID;
                        var section = element.section;
                        var column = element.column;
                        if(element.content !== undefined){
                            if(element.type == 'image') {
                                $("[data-set='"+ page + section + column +"']").attr('src', "https://koloden.com/v2api/public/cms/images/" + element.content );
                            } else if(element.type == 'text') {
                                $("[data-set='"+ page + section + column +"']").html(element.content);
                            }
                        }
                    })
                }
            })
            .catch(error => {
                console.log(error)
            });
        }

        loadPageData();
    }) 
  
  return (
    <React.Fragment>
      <div className="page-content tester-container">
        <div className="container-fluid">
            <Breadcrumbs title="Koloden" breadcrumbItem="Tester" />

            <div className="koloden-main">
                <div className="section-info">
                    <div className="container">
                        <div className="row justify-content-start">
                            <div className="col-sm-10 my-auto">
                                <p>To run a test search on a potential listing title, simply enter Title and Category below and click Submit.</p>
                            </div>
                        </div>

                        <div className="row api-table justify-content-start">
                            <div className="col-sm-10 pd-0">
                                {successMessage ? (
                                    <div className="alert alert-success fade show">
                                    <div dangerouslySetInnerHTML={{__html: successMessage}} />
                                    </div>
                                ) : null}

                                {errMessage ? (
                                    <Alert name="error-message" color="danger">{errMessage}</Alert>
                                ) : null}
                            </div>
                            {/* <div className="col-sm-2">
                                <h2 style={{maxWidth: "160px"}} className="title-v2 trydemo"><span className="secondary" data-set="421">{ showPageData ? 'Try it Out – a <br /> Demo <br />Implementation of' : '' }</span> <span data-set="422">{ showPageData ? 'Koloden’s' : '' }</span> <span className="secondary" data-set="423">{ showPageData ? 'API' : '' }</span>
                                </h2>
                            </div> */}
                            <div className="col-sm-10 pd-0">
                                <div className="box-table">
                                <AvForm name="amazon-form" className="form-horizontal" onValidSubmit={(e, v) => {handleAPIRequest(e, v)}} >
                                    <div className="form-group">
                                        <AvField value={txtWordMark} onChange={ (e) => { setTextWordMark(e.target.value) } } name="word_mark" label="Listing Title *" type="text" placeholder="e.g Fancy Baseball Bat" validate={{
                                        required: {value: true, errorMessage: "Please Enter Listing Title"}}}/>
                                    </div>
                                    <div className="form-group">
                                        <AvField value={txtCategory} onChange={ (e) => { setTextCategory(e.target.value) } } name="category" label="Goods & Services Category *" type="text" placeholder="e.g Toys" validate={{
                                        required: {value: true, errorMessage: "Please Enter Goods & Services Category"}}}/>

                                        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6LdaIOsZAAAAABGiuqZrfEWNvbybPZjZbO2uu3GU"/>

                                    </div>
                                    <div className="form-group center-cstm">
                                        <div className="submit">
                                            <Button type="submit" color="primary btn-black" className="w-md Uniwars">Submit</Button>
                                        </div>
                                    </div>
                                    </AvForm>
                                </div>
                                <div className="box-table-result tester-result">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">Word Mark Matches</th>
                                            <th scope="col">U.S. Reg. No.	</th>
                                            <th scope="col" className="col-3">Image</th>
                                            <th scope="col">Goods & Services</th>
                                        </tr>
                                        </thead>
                                        <tbody dangerouslySetInnerHTML={{__html: result_data}}>
                                        </tbody>
                                        {showLoading ? 
                                        <tr>
                                        <td colSpan={4}> <p className="blink">Please wait, while we are searching...</p> </td>
                                        </tr>
                                        : ''}
                                        <tr>
                                            <td colSpan={4} id="msg-error">Submit the form to see results here.</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          
        </div>
      </div>
    </React.Fragment>
  )
}

export default Tester